:root {
  --main-font: "PT Serif", serif;
}

.contactinfo-section {
  display: flex;
  flex-direction: column;
  padding: 1% 1% 1%;
  margin: 1%;
  background-color: rgb(241, 241, 241);
  border-radius: 12px;
}
#contactinfo_main {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}

.Contactinfo_container {
  padding: 4% 5% 2%;
  flex: 0.5;
}

.ContactForm {
  flex: 0.5;

  padding: 4% 5% 2%;
}
#Contactinfo-title {
  text-align: center;
}

#Contactinfo-title p {
  color: #02b65f;
  margin-bottom: 0.1%;
  font-family: var(--main-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

#Contactinfo-title h1 {
  color: #18191b;
  padding: 0;
  font-family: var(--main-font);
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 100% */
  letter-spacing: 2px;
  margin: 0% 0 0%;
}

#Contactinfo_container-description div {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
#Contactinfo_container-description div p {
  margin-left: 5%;
  font-size: 18px;
  letter-spacing: 1px;
}

.contactIcon {
  font-size: 28px !important;
}

.emailIcon {
  font-size: 35px !important;
}

@media screen and (max-width: 768px) {
  #contactinfo_main {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
  }
  #Contactinfo-title p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0%;
    letter-spacing: 0.4px;
  }

  #Contactinfo-title h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px; /* 100% */
    letter-spacing: 2px;
  }

  .contactinfo-section {
    padding: 5% 0 6%;
  }

  .contactIcon {
    font-size: 25px !important;
  }

  #Contactinfo_container-description div p {
    font-size: 16px;
  }

  .emailIcon {
    font-size: 30px !important;
  }
}
