:root {
  --main-font: "PT Serif", serif;
}

.service_main {
  background: linear-gradient(
    0deg,
    rgba(131, 201, 128, 0.8) 0%,
    rgba(131, 201, 128, 0.8) 100%
  );

  margin: 1%;
  border-radius: 12px;
  padding: 4% 0 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.gridContainer {
  row-gap: 30px;
}

.itemGrid_section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.itemGrid_section:not(:nth-child(4), :nth-child(8)) {
  border-right: 2px solid rgba(147, 147, 147, 0.6);
}

.itemGrid_section div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10% 8% 10%;

  width: 100%;
}

.itemGrid_section div p {
  margin: 0;
  font-size: 18px;
}

.itemGrid_section div img {
  margin-bottom: 5%;
  width: 22%;
}

.text1 {
  color: #18191b;
  text-align: center;
  font-family: var(--main-font);
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -1.5px;
  margin-top: 0;
}
.leafIMg {
  width: 62%;

  z-index: 0;
  transform: rotate(90deg);
  position: absolute;
  top: -55%;
  left: -1%;
  opacity: 0.5;
}

.small_txt {
  color: #02b65f;
  text-align: center;
  font-family: var(--main-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  z-index: 9;
  margin: 0;
}

.services_text {
  z-index: 1;
}
.services_offered {
  display: flex;
  width: 73%;
  padding: 15px 10px 15px;
  margin: 10px 0 5px 0;
  z-index: 1;

  border-radius: 39px;
  border: 0.5px solid #9d9d9d;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 900px) {
  .leafIMg {
    top: -20%;
  }
  .text1 {
    font-size: 46px;
  }
  .small_txt {
    font-size: 15px;
  }
  .itemGrid_section {
    border: none;
  }

  .services_offered {
    width: 80%;
  }

  .itemGrid_section:nth-child(odd) {
    border-right: 2px solid rgba(147, 147, 147, 0.6);
  }
  .itemGrid_section:nth-child(even) {
    border-right: none;
  }
}

@media only screen and (max-width: 600px) {
  .service_main {
    padding: 6% 0 6%;
  }
  .text1 {
    font-size: 40px;
  }
  .small_txt {
    font-size: 14px;
  }
  .leafIMg {
    display: none;
  }

  .services_offered {
    width: 80%;
    padding: 8px 10px 8px;
    margin: 2px 0 5px 0;
  }

  .gridContainer {
    row-gap: 0px;
  }
  .itemGrid_section div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3% 0% 3%;
    width: 100%;
  }

  .itemGrid_section:not(:last-child) {
    border-bottom: 2px solid rgba(147, 147, 147, 0.6);
    border-right: none;
  }

  .itemGrid_section div p {
    margin: 0;
    font-size: 16px;
  }

  .itemGrid_section div img {
    margin-bottom: 5%;
    width: 12%;
  }

  .service_main {
    padding: 10% 0 10%;
  }
}
