@media screen and (max-width: 300px) {
  .drawer {
    width: 15rem !important;
  }
}

.logo {
  width: 80px;
  height: 70px;
}
