:root {
  --main-font: "PT Serif", serif;
}

.main_about {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin: 1%;
  border-radius: 12px;
  background: linear-gradient(0deg, #eab685, #9ad297 100%);
  padding: 3% 0 3%;
}

.grain {
  overflow: hidden;
  position: absolute;
  bottom: 0%;
  fill: #eab685;
  background-image: url("../ASSETS/grain.png");
  background-repeat: repeat;
  background-size: contain;

  width: 100%;
  height: 64%;
  flex-shrink: 0;
  z-index: 1;
}

.header_div {
  display: flex;
  width: 15%;
  padding: 0.7% 1% 0.7%;
  justify-content: center;
  align-items: center;
  margin-bottom: -7.2%;
  z-index: 2;
  border-radius: 15px;
  border: 1.183px solid rgba(0, 0, 0, 0.2);
  background: rgba(226, 226, 226, 0.15);
  box-shadow: 0px 2.367px 75.732px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(30.766040802001953px);
}

.header_text {
  color: #000;
  z-index: 2;
  text-align: center;
  font-family: var(--main-font);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}

.aboutSectionDiv {
  padding: 4% 0% 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_text {
  display: flex;
  width: 65%;
  border-radius: 22px;
  z-index: 1;
  padding: 6% 6% 6%;
  justify-content: center;
  align-items: center;

  border: 2.367px solid rgba(255, 255, 255, 0.2);
  background: rgba(218, 234, 195, 0.74);
  box-shadow: 0px 2.367px 75.732px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
}

.txt {
  color: #000;
  text-align: justify;
  font-family: var(--main-font);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 175.275%; /* 35.055px */
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 900px) {
  .header_div {
    width: 20%;
    padding: 0.6% 0% 0.6%;

    margin-bottom: -8%;
  }
  .header_text {
    font-size: 20px;
  }

  .about_text {
    padding: 6% 5% 6%;
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .header_div {
    width: 50%;
    padding: 0.5% 0% 0.5%;

    margin-bottom: -10%;
  }
  .header_text {
    font-size: 19px;
  }

  .about_text {
    padding: 6% 5% 6%;
    width: 85%;
  }

  .txt {
    font-size: 16px;
  }

  .main_about {
    padding: 8% 0 10%;
  }

  .grain {
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
