:root {
  --main-font: "PT Serif", serif;
}

.HomeLandingContainer {
  margin-top: 4.5rem;
  display: flex;
  min-height: 90vh;
  background-image: url("../../../public/Images/mainillustration3.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  position: relative;
  justify-content: left;
  align-items: center;
  /* border: 1px solid red; */
}

.home-section {
  flex: 0.5;
  padding: 0% 4% 0%;
  /* border: 1px solid blue; */
}

.home-section h1 {
  color: #18191b;
  font-family: var(--main-font);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 73.297px; /* 100% */
  letter-spacing: 1px;
  margin-bottom: 2%;
  /* border: 1px solid red; */
}

.Btndiv {
  display: flex;
  width: 100%;
  justify-content: left;
}

.home-section h6 {
  width: 80%;
  margin-top: 3%;
  font-family: var(--main-font);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.504px; /* 140% */
  margin-bottom: 4%;
  letter-spacing: 0.5px;
  /* border: 1px solid red; */
}

.homeImg {
  position: absolute;
  z-index: -5;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%; /* Ensure the image doesn't shrink below its container width */
  min-height: 100%; /* Ensure the image doesn't shrink below its container height */
  bottom: 0;
  right: 0;
  left: 0%;
  top: 0;
}

@media screen and (max-width: 1230px) {
  .HomeLandingContainer {
    background-size: 45%;

    background-position: center right;
  }

  .home-section {
    padding: 0% 0% 0% 2%;
  }

  .home-section h6 {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .HomeLandingContainer {
    margin-top: 0rem;
  }

  .Btndiv {
    justify-content: center;
  }

  .HomeLandingContainer {
    background-size: 37%;

    background-position: right bottom;
    flex-direction: column;
  }

  .home-section {
    display: flex;
    flex-direction: column;
    padding: 0% 2% 0%;
    justify-content: center;
    align-items: center;
  }

  .home-section h1 {
    text-align: center;
    font-size: 50px;
    line-height: 55px; /* 100% */
  }
  .home-section h6 {
    text-align: center;
    font-size: 17px;
  }
}

@media screen and (max-width: 650px) {
  .HomeLandingContainer {
    background-size: 21vh;

    background-position: right bottom;
  }
  .Btndiv {
    justify-content: center;
  }
}
