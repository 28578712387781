:root {
  --main-font: "PT Serif", serif;
}

.galeryHeader {
  background: linear-gradient(
    233deg,
    rgba(231, 159, 93, 0.73) 3.34%,
    #83c980 71.5%
  );

  margin: 1%;
  border-radius: 12px;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #18191b;
}
.heading h1 {
  /* Font & Text */
  font-family: var(--main-font);
  font-size: 50px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  letter-spacing: -1.5px;
  line-height: 60px;

  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  color: rgb(24, 25, 27);
}

.grid_container {
  padding: 2% 3% 7%;

  width: 100%;
}

.grid_item div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pic {
  width: 80%;
  z-index: 5;
  height: auto;
  object-fit: contain;
  border-radius: 16px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 900px) {
  .grid_item:nth-child(3),
  .grid_item:nth-child(4) {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .grid_item:nth-child(2),
  .grid_item:nth-child(3),
  .grid_item:nth-child(4) {
    margin-top: 5%;
  }

  .pic {
    width: 75%;
  }

  .heading h1 {
    font-size: 40px;
  }
}
